import React from "react";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import { ReactComponent as NotFoundIcon } from "../../resources/not-found.svg";

interface ErrorPageProps {
  baseUrl?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ baseUrl }) => {
  if (baseUrl === undefined) {
    console.warn(
      "No `baseUrl` is specified for <RootErrorBoundary>. Please add it so the redirect to background image and the homepage works correctly.`"
    );
  }

  return (
    <div className="container h-100 mb-5">
      <div className="col-12 h-100 ">
        <div className="row justify-content-center align-items-center h-75 mb-3">
          <div className="col-5">
            <NotFoundIcon
              style={{
                height: "45vh",
                width: "auto",
              }}
            />
          </div>
        </div>
        <div className="row justify-content-center align-items-center mb-3">
          <h1>
            UPS! Se ha producido un error inesperado. Si el error persiste
            contacte con el administrador
          </h1>
        </div>{" "}
        <br />
        <div className="row justify-content-center align-items-center mb-3">
          <div className="col-6">
            <Button
              block
              appearance="ghost"
              onClick={() => {
                window.location.reload();
              }}
            >
              Volver a la página principal
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
