import React from "react";
import { Whisper, Popover, Icon } from "rsuite";
import { defaultPalette } from "../../../../contexts/ThemeContext/theme.state";
import { useQueryParams } from "../../../../hooks/useQueryParams";

interface PreviewBannerProps {}

export const PreviewBanner: React.FC<PreviewBannerProps> = ({}) => {
  const query = useQueryParams();
  console.log(query.get("preview"));
  return (
    <div
      className="row w-100 justify-content-center"
      style={{
        position: "absolute",
        right: "15px",
        borderTop: `solid 5px ${defaultPalette.primary}`,
        marginTop: 0,
        visibility: query.get("preview") ? "visible" : "hidden",
        transition: "0.5s",
        zIndex: 1,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-1px",
          backgroundColor: defaultPalette.primary,
          color: "white",
          padding: "0 10px 5px 10px",
          borderRadius: "0 0 6px 6px",
        }}
      >
        Previsualización
        <Whisper
          placement="bottom"
          speaker={
            <Popover style={{ width: "400px" }}>
              Esto es una previsualización de cómo se vería tu calculadora solar
              con SolarForm
            </Popover>
          }
        >
          <Icon icon="info" style={{ marginLeft: "6px" }} />
        </Whisper>
      </div>
    </div>
  );
};
