import React, { Suspense, lazy } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";
import { PreviewBanner } from "../Apps/SolarEnergy/SolarForm/PreviewBanner";
import { LoadingScreen } from "../layout/LoadingScreen";
interface RouterProps {
  basename?: string;
}

/* CODE SPLITING LAZY IMPORTS */

const SolarForm = lazy(
  () => import("../Apps/SolarEnergy/SolarForm/SolarFormIndex")
);
const Ping = lazy(() => import("../../Ping"));

/* ============================ */

export const Routes: React.FC<RouterProps> = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <PreviewBanner />
        <Switch>
          {/*AppAccessRouteGuard prevents user to navigate through url to sections it has no acces to redirecting to launcher */}
          <Route exact path={"/"} component={SolarForm} />
          <Route path={"/ping"} component={Ping} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
