import React, { useEffect, useReducer } from "react";

import sessionReducer from "./theme.reducer";
import { ThemeContext, ThemePalette } from "./theme.context";
import { ClientThemeConfigDto } from "../../api/model/security/typings/ClientThemeConfig.dto";

// interface SessionStateProps {
//     children: ReactNode
// }
export const defaultPalette: ThemePalette = {
  primary: "#e75679",
  btnPrimary: "#f7a702",
  btnSecondary: "#f7a702",
  background: "#f1f1f1",
  navbarBackgroundColor: "#f7f7fa",
  graphSeries: {
    graph1: "#e75679",
    graph2: "#f7a702",
    graph3: "#816bff",
    graph4: "#fca4bd",
    graph5: "#6151c2",
    graph6: "#202B44",
  },
};
export const generateGraphColorway = (palette: ThemePalette): string[] => {
  try {
    return [
      palette.graphSeries.graph1,
      palette.graphSeries.graph2,
      palette.graphSeries.graph3,
      palette.graphSeries.graph4,
      palette.graphSeries.graph5,
      palette.graphSeries.graph6,
    ];
  } catch (err) {
    const defaultPalette: ThemePalette = {
      primary: "#5646ff",
      btnPrimary: "#5646ff",
      btnSecondary: "#202b44",
      background: "#f1f1f1",
      navbarBackgroundColor: "#f7f7fa",
      graphSeries: {
        graph1: "#5646ff",
        graph2: "#202b44",
        graph3: "#293F91",
        graph4: "#7C7C7C",
        graph5: "#5959FF",
        graph6: "#202B44",
      },
    };
    return generateGraphColorway(defaultPalette);
  }
};
export const defaultTheme: ThemeContextState = {
  palette: defaultPalette,
  graphColorway: generateGraphColorway(defaultPalette),
};
/**
 * Generates theme object from session context usser theme config
 */
export const generateThemeFromSession = (
  themeConfig?: ClientThemeConfigDto
): ThemeContextState => {
  if (!themeConfig) {
    return defaultTheme;
  }
  const palette = generatePaletteFromTheme(themeConfig);
  return {
    palette,
    graphColorway: generateGraphColorway(palette),
    logoUrl: themeConfig.logoUrl,
  };
};

export type ThemeContextState = {
  palette: ThemePalette;
  graphColorway: string[];
  logoUrl?: string;
  faviconUrl?: string;
  clientAppTitle?: string;
};

export const generatePaletteFromTheme = (
  theme: ClientThemeConfigDto
): ThemePalette => {
  return {
    btnPrimary: theme.btnPrimary || defaultPalette.btnPrimary,
    primary: theme.primary || defaultPalette.primary,
    background: theme.background || defaultPalette.background,
    btnSecondary: theme.btnSecondary || defaultPalette.btnSecondary,
    navbarBackgroundColor:
      theme.navbarBackgroundColor || defaultPalette.navbarBackgroundColor,
    graphSeries: {
      graph1: theme.graph1 || defaultPalette.graphSeries.graph1,
      graph2: theme.graph2 || defaultPalette.graphSeries.graph2,
      graph3: theme.graph3 || defaultPalette.graphSeries.graph3,
      graph4: theme.graph4 || defaultPalette.graphSeries.graph4,
      graph5: theme.graph5 || defaultPalette.graphSeries.graph5,
      graph6: theme.graph6 || defaultPalette.graphSeries.graph6,
    },
  };
};
interface ThemeStateProps {
  theme?: ThemeContextState;
  children: any;
}
/**
 * Theme states that can recieve a theme object. In case the object is defined, the state is set to that theme. In case it doesnt
 * //state is set to default theme
 *
 * @returns
 */
export function ThemeState({ theme, children }: ThemeStateProps): JSX.Element {
  const initialState: ThemeContextState = {
    palette: localStorage.getItem("theme-palette")
      ? JSON.parse(localStorage.getItem("theme-palette")!)
      : defaultPalette,
    graphColorway: localStorage.getItem("theme-palette")
      ? generateGraphColorway(
          JSON.parse(localStorage.getItem("theme-palette")!)
        )
      : generateGraphColorway(defaultPalette),
    faviconUrl: localStorage.getItem("theme-favicon-url")
      ? localStorage.getItem("theme-favicon-url") || undefined
      : undefined,
    logoUrl: localStorage.getItem("theme-logo-url")
      ? localStorage.getItem("theme-logo-url") || undefined
      : undefined,
    clientAppTitle: localStorage.getItem("theme-client-app-title")
      ? localStorage.getItem("theme-client-app-title") || undefined
      : undefined,
  };
  //"theme-favicon-url"
  const [state, dispatch] = useReducer(sessionReducer, initialState);

  useEffect(() => {
    dispatch({ payload: initialState, type: "UPDATE_STATE" });
  }, [theme]);
  //Fucntion to edit palette
  const setPalette = (palette: ThemePalette) => {
    dispatch({
      type: "SET_THEME_PALLETE",
      payload: palette,
    });
    localStorage.setItem("theme-palette", JSON.stringify(palette));
  };

  const setLogoUrl = (url: string) => {
    dispatch({
      type: "SET_LOGO_URL",
      payload: url,
    });
    localStorage.setItem("theme-logo-url", url);
  };

  const setFaviconUrl = (url: string) => {
    dispatch({
      type: "SET_FAVICON_URL",
      payload: url,
    });
    localStorage.setItem("theme-favicon-url", url);
  };

  const setClientAppTitle = (title: string) => {
    dispatch({
      type: "SET_APP_TITLE",
      payload: title,
    });
    localStorage.setItem("theme-client-app-title", title);
  };

  return (
    <ThemeContext.Provider
      value={
        theme
          ? {
              ...theme,
              setPalette,
              setLogoUrl,
              setFaviconUrl,
              setClientAppTitle,
            }
          : {
              ...state,
              setPalette,
              setLogoUrl,
              setFaviconUrl,
              setClientAppTitle,
            }
      }
    >
      {children}
    </ThemeContext.Provider>
  );
}
