import React, { useContext } from "react";
import { Licenses } from "../../api/model/security/typings/Licenses.enum";
import { ThemeContext } from "../../contexts/ThemeContext/theme.context";

interface ThemeProviderProps {
  classPrefix?: string;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  classPrefix,
}) => {
  const { palette } = useContext(ThemeContext);
  //Function to change favicon if it is present on user theme (if license is organization or corporation)

  let clientStyles = `
  body {
   
    --primary: ${palette.primary}!important;
    --btn-primary: ${palette.btnPrimary};
    --btn-secondary: ${palette.btnSecondary};
    --background: ${palette.background};
    

  }

  #header-shape-gradient {
   
  
    --color-top: ${palette.primary}!important;
    --color-stop: ${palette.btnSecondary}!important;
  }
  `;
  if (classPrefix) {
    clientStyles = `
  .${classPrefix}{
   
    --primary-${classPrefix}: ${palette.primary};
    --btn-primary-${classPrefix}: ${palette.btnPrimary};
    --btn-secondary-${classPrefix}: ${palette.btnSecondary};
    --background-${classPrefix}: ${palette.background};
    

  }
 
 

  .${classPrefix} .color-primary {
    color: var(--primary-${classPrefix}) !important;
  }
  .${classPrefix} .border-color-primary {
    border-color: var(--primary-${classPrefix}) !important;
  }
  .${classPrefix} .color-secondary {
    color: var(--btn-secondary-${classPrefix}) !important;
  }
  .${classPrefix} .rs-btn-primary {
    color: #ffffff;
    background-color: var(--btn-primary-${classPrefix}) !important;
  }
  .${classPrefix} .primary-badge {
    background-color: var(--btn-primary-${classPrefix}) !important;
    color: white !important;
  }

  .${classPrefix} .rs-btn-primary {
    color: #ffffff;
    background-color: var(--btn-primary-${classPrefix}) !important;
  }
  .${classPrefix} .primary-badge {
    background-color: var(--btn-primary-${classPrefix}) !important;
    color: white !important;
  }
  .${classPrefix} .rs-picker-input:not(.rs-picker-disabled):hover,
  .rs-picker-input.rs-picker-focused {
    border-color: var(--btn-primary-${classPrefix}) !important;
  }
  .${classPrefix} .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
  .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
    border-color: var(--btn-primary-${classPrefix}) !important;
  }
  .${classPrefix} .rs-btn-primary:active,
  .rs-btn-primary.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary {
    color: #ffffff;
    background-color: var(--btn-secondary-${classPrefix}) !important;
  }
  .${classPrefix} .rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: var(--btn-secondary-${classPrefix}) !important;
  }
  .${classPrefix} .rs-btn-ghost {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: var(--primary-${classPrefix}) !important;
    border: 1px solid var(--primary-${classPrefix}) !important;
    background-color: transparent;
  }
  .${classPrefix} .rs-btn-ghost.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost {
    color: var(--btn-secondary-${classPrefix}) !important;
    border-color: var(--btn-secondary-${classPrefix}) !important;
    background-image: none;
  }
  .${classPrefix} .rs-steps-item-status-process
    .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-color: var(--btn-primary-${classPrefix}) !important;
    background: var(--btn-primary-${classPrefix}) !important;
    color: #fff;
  }
  .${classPrefix} .rs-nav-subtle.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content::before {
    background-color: var(--primary-${classPrefix}) !important;
  }
  .${classPrefix} .nav-active > .rs-nav-item-content {
    color: var(--primary-${classPrefix}) !important;
  }
  
  .${classPrefix} .primary-text {
    color: var(--primary-${classPrefix});
  }

  .${classPrefix} .primary-text {
    color: var(--primary-${classPrefix});
}

  #header-shape-gradient {
   
  
    --color-top: ${palette.primary}!important;
    --color-stop: ${palette.btnSecondary}!important;
  }
  `;
  }

  return (
    <>
      <style>{clientStyles}</style>
      {children}
    </>
  );
};
