import { generateGraphColorway, ThemeContextState } from "./theme.state";
import { ThemeAction } from "./types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: ThemeContextState,
  action: ThemeAction
): ThemeContextState => {
  switch (action.type) {
    case "SET_THEME_PALLETE":
      return {
        ...state,
        palette: {
          ...action.payload,
        },
        graphColorway: generateGraphColorway(action.payload),
      };

    case "SET_FAVICON_URL":
      return {
        ...state,
        faviconUrl: action.payload,
      };
    case "SET_LOGO_URL":
      return {
        ...state,
        logoUrl: action.payload,
      };
    case "SET_APP_TITLE":
      return {
        ...state,
        clientAppTitle: action.payload,
      };
    case "UPDATE_STATE": {
      return action.payload;
    }
    default:
      return state;
  }
};
