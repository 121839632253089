import "rsuite/dist/styles/rsuite-default.css";
import "./bootstrap.min.css";
import "./index.css";
import { Routes } from "./components/Router/Routes";

import { ThemeProvider } from "./components/layout/ThemeProvider";
import { defaultTheme, ThemeState } from "./contexts/ThemeContext/theme.state";
import { RootErrorBoundary } from "./components/ErrorBoundary/RootErrorBoundary";

function App() {
  return (
    <div
      style={{
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      id="app-container"
    >
      <RootErrorBoundary>
        <ThemeState>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </ThemeState>
      </RootErrorBoundary>
    </div>
  );
}

export default App;
