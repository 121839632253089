import { createContext } from "react";
import { StringMappingType } from "typescript";

export interface ThemeContextType {
  palette: ThemePalette;
  logoUrl?: string;
  faviconUrl?: string;
  graphColorway: string[];
  clientAppTitle?: string;
  setPalette: (palette: ThemePalette) => void;
  setLogoUrl: (url: string) => void;
  setFaviconUrl: (url: string) => void;
  setClientAppTitle: (name: string) => void;
}

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType
);

export interface ThemePalette {
  primary: string;
  btnPrimary: string;
  btnSecondary: string;
  background: string;
  navbarBackgroundColor: string;

  graphSeries: {
    graph5: string;
    graph6: string;
    graph1: string;
    graph2: string;
    graph3: string;
    graph4: string;
  };
}
