import React from "react";
import { ReactComponent as SuntropyLoader } from "../../resources/suntropy_loader.svg";

interface LoadingScreenProps {}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({}) => {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center h-100 w-100 m-0">
      {/* <Spinner
            className="loading text-center"
            name="folding-cube"
            color="blue"
            fadeIn="none"
            style={{
              position: "relative",
              top: "-3rem",
              height: "3rem",
              width: "3rem",
            }}
          /> */}
      <SuntropyLoader
        style={{
          height: "45vh",
          width: "auto",
        }}
      />
    </div>
  );
};
