import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./ErrorPage";

export const RootErrorBoundary = ({ baseUrl, children }: any) => {
  return (
    <ErrorBoundary
      FallbackComponent={(p) => <FallbackComponent baseUrl={baseUrl} />}
    >
      {children}
    </ErrorBoundary>
  );
};

const FallbackComponent = (props) => {
  return <ErrorPage baseUrl={props.baseUrl} />;
};
